import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import ServerDataTable from '../../data-table/ServerDataTable1.tsx'
import moment from 'moment'
import { RotateCcw } from 'lucide-react'
import { CCard, CCardBody, CCardTitle, CCardText, CContainer, CRow, CCol } from '@coreui/react'
import { any } from 'prop-types'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Visibility } from '@mui/icons-material'
const baseUrl = process.env.REACT_APP_URL
const GetInactiveUsers = () => {
  // Tanstack table related states start
  const [pageSize, setPageSize] = useState(10)
  const [pageIndex, setPageIndex] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [totalVerificationCount, setTotalVerificationCount] = useState(0)
  const [totalGuestCount, setTotalGuestCount] = useState(0)
  const [totalPendingVerification, setTotalPendingVerification] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [userData, setUserData] = useState([]) // State to hold user data
  const [sorting, setSorting] = useState([])
  const [totalUsersWithoutSearchAndFilters, setTotalUsersWithoutSearchAndFilters] = useState(0)
  const [fixTotalFilteredUsers, setFixTotalFilteredUsers] = useState(0)
  const [fixVerifiedCount, setFixVerifiedCount] = useState(0)
  const [fixGuestCount, setFixGuestCount] = useState(0)

  // Tanstack table related states end

  // Filter states
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedGender, setSelectedGender] = useState('')
  const [selectedEducation, setSelectedEducation] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedPlatform, setSelectedPlatform] = useState('')

  // const today = new Date().toISOString().split('T')[0] // Get today's date in YYYY-MM-DD format

  const today = new Date()
  const month = String(today.getMonth() + 1).padStart(2, '0') // Months are 0-based
  const day = String(today.getDate()).padStart(2, '0')
  const year = today.getFullYear()
  const formattedDate = `${month}-${day}-${year}` // MM-DD-YYYY
  console.log(formattedDate) // Output: "10-05-2023"
  const [fromDate, setFromDate] = useState(today)
  const [toDate, setToDate] = useState(today)
  const [dateFilterType, setDateFilterType] = useState('custom') // Default to 'custom'
  const [isDateFilterActive, setIsDateFilterActive] = useState(false)

  // Check if any filter is active
  const isAnyFilterActive = () => {
    return (
      selectedGender !== '' ||
      selectedEducation !== '' ||
      selectedCountry !== '' ||
      selectedPlatform !== '' ||
      searchQuery !== '' ||
      isDateFilterActive
    )
  }

  // Reset all filters
  const handleResetFilters = () => {
    setSearchQuery('')
    setSelectedGender('')
    setSelectedEducation('')
    setSelectedCountry('')
    setSelectedPlatform('')
    setFromDate(today) // Reset to empty string
    setToDate(today) // Reset to empty string
    setDateFilterType('custom') // Reset to 'custom'
    setIsDateFilterActive(false) // Reset the flag
  }

  // Handle date filter type change
  const handleDateFilterTypeChange = (e) => {
    const type = e.target.value
    setDateFilterType(type)

    // If changing from one type to another and there's a date selected, update accordingly
    if (!fromDate) {
      setIsDateFilterActive(true)
    }
  }

  const formatDateForBackend = (date, isStartOfDay = true) => {
    const formattedDate = moment(date).format('YYYY-MM-DD')
    return isStartOfDay ? `${formattedDate} 00:00:00` : `${formattedDate} 23:59:59`
  }

  const handleFromDateChange = (date) => {
    if (date > toDate) {
      // If the new fromDate is after the current toDate, reset toDate to the new fromDate
      setToDate(date)
    }
    setFromDate(date)
    setIsDateFilterActive(true)
  }
  const handleToDateChange = (date) => {
    if (date < fromDate) {
      // If the new toDate is before the current fromDate, reset fromDate to the new toDate
      setFromDate(date)
    }
    setToDate(date)
    setIsDateFilterActive(true)
  }

  const disableToDatesBeforeFromDate = (date) => {
    const today = new Date() // Get the current date
    return date > fromDate && date < today // Disable dates before fromDate and after today
  }
  // Disable dates in the fromDate picker that are after the selected toDate
  const disableFromDatesAfterToDate = (date) => {
    return date < toDate
  }

  // Calculate toDate based on fromDate and dateFilterType
  useEffect(() => {
    if (fromDate && dateFilterType !== 'custom') {
      let newToDate

      switch (dateFilterType) {
        case 'day':
          newToDate = new Date(fromDate)
          newToDate.setDate(newToDate.getDate())
          break

        case 'week':
          newToDate = new Date(fromDate)
          newToDate.setDate(newToDate.getDate() + 6)
          break

        case 'month':
          // Calculate the last day of the month
          newToDate = new Date(
            fromDate.getFullYear(),
            fromDate.getMonth() + 1, // Next month
            0, // Day 0 of the next month is the last day of the current month
          )
          break

        default:
          break
      }

      if (newToDate) {
        setToDate(newToDate)
      }
    }
  }, [fromDate, dateFilterType]) // Re-run effect when fromDate or dateFilterType changes

  // Tanstack table related states and functions start here

  const GameIcon = (
    <svg width="18" height="18" viewBox="0 0 25 25" fill="black" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9481 2.10404C11.4548 2.18112 11.7358 1.92211 7.03942 6.63006C6.10201 7.56973 5.29062 8.39373 5.23641 8.46112C5.1821 8.5286 5.12525 8.59279 5.11001 8.6039C5.04714 8.64954 4.90897 9.11288 4.90897 9.27825C4.90897 9.56013 5.00968 9.89256 5.1918 10.2122C5.34248 10.4767 5.70605 10.7121 6.10643 10.8046C6.31057 10.8517 18.1888 10.8586 18.3429 10.8116C18.3987 10.7946 18.5153 10.76 18.602 10.7349C19.4431 10.4903 19.8339 9.31524 19.3256 8.559C19.2272 8.41246 13.357 2.52032 13.1387 2.34884C12.9763 2.22131 12.7373 2.12568 12.5259 2.10366C12.4103 2.09152 12.2921 2.0775 12.2632 2.07232C12.2343 2.06724 12.0925 2.08145 11.9481 2.10404ZM15.1611 6.40361C17.2496 8.4919 18.0067 9.26997 18.0067 9.32794V9.40691L12.2374 9.39806C6.84507 9.38978 6.46756 9.38536 6.45692 9.32983C6.44017 9.24314 12.1358 3.55825 12.2392 3.55825C12.2937 3.55825 13.1303 4.37293 15.1611 6.40361ZM6.27471 13.8578C5.74925 13.9613 5.58389 14.0292 5.40506 14.2151C5.26803 14.3576 5.01429 14.7592 5.01354 14.8349C5.01325 14.8638 4.99029 14.9504 4.96252 15.0275C4.83565 15.3794 4.92958 15.7948 5.22361 16.1832C5.29429 16.2766 11.2006 22.1843 11.3352 22.2963C11.6979 22.5981 12.4573 22.664 12.9141 22.4335C13.1693 22.3048 19.0625 16.479 19.3262 16.0948C19.8576 15.3206 19.4153 14.125 18.5235 13.9246C18.4222 13.9019 18.2823 13.8688 18.2127 13.8512C18.0484 13.8097 6.48667 13.8161 6.27471 13.8578ZM18.0379 15.3182C18.0788 15.3946 12.3591 21.1386 12.2422 21.1386C12.1596 21.1386 6.44987 15.4372 6.44987 15.3547C6.44987 15.2587 6.32582 15.2607 12.2708 15.2579C17.7592 15.2553 18.0056 15.2578 18.0379 15.3182Z"
        fill="black"
      />
    </svg>
  )

  function getCountryName(countryCode) {
    const countryObj = allContries.find((country) => country.code === countryCode)
    return countryObj ? countryObj.name : countryCode
  }

  const handlePaginationChange = (pagination) => {
    console.log('Pagination: ', pagination)
    setPageSize(pagination.pageSize)
    if (pagination.pageIndex === 0) {
      setPageIndex(1)
    } else {
      setPageIndex(pagination.pageIndex + 1)
    }
  }

  const handleSortingChange = (newSorting) => {
    console.log('New Sorting:', newSorting)
    setSorting(newSorting)
  }
  // response: data->users, totalRows, pageCount, pageIndex, limit

  const fetchPaginatedUsers = async () => {
    try {
      const dataToSend = {
        pageSize,
        pageIndex: pageIndex === 0 ? 1 : pageIndex,
        searchQuery,
        gender: selectedGender,
        education: selectedEducation,
        country: selectedCountry,
        platform: selectedPlatform,
        sorting,
        ...(isDateFilterActive && {
          fromDate: fromDate ? formatDateForBackend(fromDate, true) : null,
          toDate: toDate ? formatDateForBackend(toDate, false) : null,
        }),
      }
      const response = await axios.post(`${baseUrl}/v1/getInactiveUsers`, dataToSend)
      return response.data
    } catch (error) {
      console.log('Error while fetching users: ', error)
    }
  }

  const {
    data: usersList,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: [
      'userList',
      {
        pageIndex,
        pageSize,
        searchQuery,
        selectedGender,
        selectedEducation,
        selectedCountry,
        selectedPlatform,
        isDateFilterActive,
        fromDate,
        toDate,
        sorting,
      },
    ],
    staleTime: 1000 * 60 * 2,
    retry: 1,
    retryDelay: 1000,
    queryFn: fetchPaginatedUsers,
  })

  const UsersCols = [
    {
      id: 'id',
      header: 'ID',
      cell: ({ row }) => {
        return <span>{pageSize * (pageIndex - 1) + row.index + 1}</span>
      },
    },
    { accessorKey: 'username', header: 'Username', enableSorting: false },
    { accessorKey: 'email', header: 'Email', enableSorting: false },
    { accessorKey: 'role', header: 'Role', enableSorting: false },
    {
      accessorKey: 'gender',
      header: 'Gender',
      cell: ({ row }) => {
        const data = row.original
        return <span>{data?.gender ? data.gender : 'N/A'}</span>
      },
      enableSorting: false,
    },
    {
      accessorKey: 'education',
      header: 'Education',
      cell: ({ row }) => {
        const data = row.original
        return <span>{data?.education ? data.education : 'N/A'}</span>
      },
      enableSorting: false,
    },
    {
      accessorKey: 'country',
      header: 'Country',
      cell: ({ row }) => {
        const data = row.original
        return (
          <span>
            {data?.geolocation?.country
              ? getCountryName(data?.geolocation?.country)
              : getCountryName(data?.country) || 'N/A'}
          </span>
        )
      },
      enableSorting: false,
    },
    {
      accessorKey: 'geolocation.city',
      header: 'City',
      cell: ({ row }) => {
        const data = row.original
        return <span>{data?.geolocation?.city || 'N/A'}</span>
      },
      enableSorting: false,
    },
    // {
    //   accessorKey: 'totalGames',
    //   header: () => (
    //     <div className="flex items-center gap-2">
    //       <span>Total Game</span>
    //     </div>
    //   ),
    //   cell: ({ row }) => {
    //     const data = row.original
    //     return <span>{data?.totalGames || 0}</span>
    //   },
    //   enableSorting: true,
    // },

    // {
    //   accessorKey: 'soloGames',
    //   header: () => (
    //     <div className="flex items-center gap-2">
    //       <span>Solo</span>
    //     </div>
    //   ),
    //   cell: ({ row }) => {
    //     const data = row.original
    //     return <span>{data?.soloGames || 0}</span>
    //   },
    //   enableSorting: true,
    // },
    // {
    //   accessorKey: 'partneredGames',
    //   header: () => (
    //     <div className="flex items-center gap-2">
    //       <span>Partnered</span>
    //     </div>
    //   ),
    //   cell: ({ row }) => {
    //     const data = row.original
    //     return <span>{data?.partneredGames || 0}</span>
    //   },
    //   enableSorting: true,
    // },
    // {
    //   accessorKey: 'voucher',
    //   header: 'Voucher',
    //   cell: ({ row }) => {
    //     const data = row.original
    //     return <span>{data?.voucher ? data.voucher : 0}</span>
    //   },
    //   enableSorting: false,
    // },
    { accessorKey: 'lastLoginDevice.deviceName', header: 'Platform', enableSorting: false },
    {
      accessorKey: 'createdAt',
      header: () => (
        <div className="flex items-center gap-2">
          <span>Created Date</span>
        </div>
      ),
      cell: ({ row }) => {
        const data = row.original
        return <span>{moment(data.createdAt).format('MM-DD-YYYY HH:mm:ss')}</span>
      },
      enableSorting: true,
    },
  ]

  const allContries = [
    { name: 'Afghanistan', code: 'AF' },
    { name: 'Aland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Algeria', code: 'DZ' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'AndorrA', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Anguilla', code: 'AI' },
    { name: 'Antarctica', code: 'AQ' },
    { name: 'Antigua and Barbuda', code: 'AG' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Aruba', code: 'AW' },
    { name: 'Australia', code: 'AU' },
    { name: 'Austria', code: 'AT' },
    { name: 'Azerbaijan', code: 'AZ' },
    { name: 'Bahamas', code: 'BS' },
    { name: 'Bahrain', code: 'BH' },
    { name: 'Bangladesh', code: 'BD' },
    { name: 'Barbados', code: 'BB' },
    { name: 'Belarus', code: 'BY' },
    { name: 'Belgium', code: 'BE' },
    { name: 'Belize', code: 'BZ' },
    { name: 'Benin', code: 'BJ' },
    { name: 'Bermuda', code: 'BM' },
    { name: 'Bhutan', code: 'BT' },
    { name: 'Bolivia', code: 'BO' },
    { name: 'Bosnia and Herzegovina', code: 'BA' },
    { name: 'Botswana', code: 'BW' },
    { name: 'Bouvet Island', code: 'BV' },
    { name: 'Brazil', code: 'BR' },
    { name: 'British Indian Ocean Territory', code: 'IO' },
    { name: 'Brunei Darussalam', code: 'BN' },
    { name: 'Bulgaria', code: 'BG' },
    { name: 'Burkina Faso', code: 'BF' },
    { name: 'Burundi', code: 'BI' },
    { name: 'Cambodia', code: 'KH' },
    { name: 'Cameroon', code: 'CM' },
    { name: 'Canada', code: 'CA' },
    { name: 'Cape Verde', code: 'CV' },
    { name: 'Cayman Islands', code: 'KY' },
    { name: 'Central African Republic', code: 'CF' },
    { name: 'Chad', code: 'TD' },
    { name: 'Chile', code: 'CL' },
    { name: 'China', code: 'CN' },
    { name: 'Christmas Island', code: 'CX' },
    { name: 'Cocos (Keeling) Islands', code: 'CC' },
    { name: 'Colombia', code: 'CO' },
    { name: 'Comoros', code: 'KM' },
    { name: 'Congo', code: 'CG' },
    { name: 'Congo, The Democratic Republic of the', code: 'CD' },
    { name: 'Cook Islands', code: 'CK' },
    { name: 'Costa Rica', code: 'CR' },
    { name: "Cote D'Ivoire", code: 'CI' },
    { name: 'Croatia', code: 'HR' },
    { name: 'Cuba', code: 'CU' },
    { name: 'Cyprus', code: 'CY' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Djibouti', code: 'DJ' },
    { name: 'Dominica', code: 'DM' },
    { name: 'Dominican Republic', code: 'DO' },
    { name: 'Ecuador', code: 'EC' },
    { name: 'Egypt', code: 'EG' },
    { name: 'El Salvador', code: 'SV' },
    { name: 'Equatorial Guinea', code: 'GQ' },
    { name: 'Eritrea', code: 'ER' },
    { name: 'Estonia', code: 'EE' },
    { name: 'Ethiopia', code: 'ET' },
    { name: 'Falkland Islands (Malvinas)', code: 'FK' },
    { name: 'Faroe Islands', code: 'FO' },
    { name: 'Fiji', code: 'FJ' },
    { name: 'Finland', code: 'FI' },
    { name: 'France', code: 'FR' },
    { name: 'French Guiana', code: 'GF' },
    { name: 'French Polynesia', code: 'PF' },
    { name: 'French Southern Territories', code: 'TF' },
    { name: 'Gabon', code: 'GA' },
    { name: 'Gambia', code: 'GM' },
    { name: 'Georgia', code: 'GE' },
    { name: 'Germany', code: 'DE' },
    { name: 'Ghana', code: 'GH' },
    { name: 'Gibraltar', code: 'GI' },
    { name: 'Greece', code: 'GR' },
    { name: 'Greenland', code: 'GL' },
    { name: 'Grenada', code: 'GD' },
    { name: 'Guadeloupe', code: 'GP' },
    { name: 'Guam', code: 'GU' },
    { name: 'Guatemala', code: 'GT' },
    { name: 'Guernsey', code: 'GG' },
    { name: 'Guinea', code: 'GN' },
    { name: 'Guinea-Bissau', code: 'GW' },
    { name: 'Guyana', code: 'GY' },
    { name: 'Haiti', code: 'HT' },
    { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
    { name: 'Holy See (Vatican City State)', code: 'VA' },
    { name: 'Honduras', code: 'HN' },
    { name: 'Hong Kong', code: 'HK' },
    { name: 'Hungary', code: 'HU' },
    { name: 'Iceland', code: 'IS' },
    { name: 'India', code: 'IN' },
    { name: 'Indonesia', code: 'ID' },
    { name: 'Iran, Islamic Republic Of', code: 'IR' },
    { name: 'Iraq', code: 'IQ' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Isle of Man', code: 'IM' },
    { name: 'Israel', code: 'IL' },
    { name: 'Italy', code: 'IT' },
    { name: 'Jamaica', code: 'JM' },
    { name: 'Japan', code: 'JP' },
    { name: 'Jersey', code: 'JE' },
    { name: 'Jordan', code: 'JO' },
    { name: 'Kazakhstan', code: 'KZ' },
    { name: 'Kenya', code: 'KE' },
    { name: 'Kiribati', code: 'KI' },
    { name: 'Korea, Democratic PeopleS Republic of', code: 'KP' },
    { name: 'Korea, Republic of', code: 'KR' },
    { name: 'Kuwait', code: 'KW' },
    { name: 'Kyrgyzstan', code: 'KG' },
    { name: "Lao People'S Democratic Republic", code: 'LA' },
    { name: 'Latvia', code: 'LV' },
    { name: 'Lebanon', code: 'LB' },
    { name: 'Lesotho', code: 'LS' },
    { name: 'Liberia', code: 'LR' },
    { name: 'Libyan Arab Jamahiriya', code: 'LY' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Lithuania', code: 'LT' },
    { name: 'Luxembourg', code: 'LU' },
    { name: 'Macao', code: 'MO' },
    { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
    { name: 'Madagascar', code: 'MG' },
    { name: 'Malawi', code: 'MW' },
    { name: 'Malaysia', code: 'MY' },
    { name: 'Maldives', code: 'MV' },
    { name: 'Mali', code: 'ML' },
    { name: 'Malta', code: 'MT' },
    { name: 'Marshall Islands', code: 'MH' },
    { name: 'Martinique', code: 'MQ' },
    { name: 'Mauritania', code: 'MR' },
    { name: 'Mauritius', code: 'MU' },
    { name: 'Mayotte', code: 'YT' },
    { name: 'Mexico', code: 'MX' },
    { name: 'Micronesia, Federated States of', code: 'FM' },
    { name: 'Moldova, Republic of', code: 'MD' },
    { name: 'Monaco', code: 'MC' },
    { name: 'Mongolia', code: 'MN' },
    { name: 'Montenegro', code: 'ME' },
    { name: 'Montserrat', code: 'MS' },
    { name: 'Morocco', code: 'MA' },
    { name: 'Mozambique', code: 'MZ' },
    { name: 'Myanmar', code: 'MM' },
    { name: 'Namibia', code: 'NA' },
    { name: 'Nauru', code: 'NR' },
    { name: 'Nepal', code: 'NP' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'Netherlands Antilles', code: 'AN' },
    { name: 'New Caledonia', code: 'NC' },
    { name: 'New Zealand', code: 'NZ' },
    { name: 'Nicaragua', code: 'NI' },
    { name: 'Niger', code: 'NE' },
    { name: 'Nigeria', code: 'NG' },
    { name: 'Niue', code: 'NU' },
    { name: 'Norfolk Island', code: 'NF' },
    { name: 'Northern Mariana Islands', code: 'MP' },
    { name: 'Norway', code: 'NO' },
    { name: 'Oman', code: 'OM' },
    { name: 'Pakistan', code: 'PK' },
    { name: 'Palau', code: 'PW' },
    { name: 'Palestinian Territory, Occupied', code: 'PS' },
    { name: 'Panama', code: 'PA' },
    { name: 'Papua New Guinea', code: 'PG' },
    { name: 'Paraguay', code: 'PY' },
    { name: 'Peru', code: 'PE' },
    { name: 'Philippines', code: 'PH' },
    { name: 'Pitcairn', code: 'PN' },
    { name: 'Poland', code: 'PL' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Puerto Rico', code: 'PR' },
    { name: 'Qatar', code: 'QA' },
    { name: 'Reunion', code: 'RE' },
    { name: 'Romania', code: 'RO' },
    { name: 'Russian Federation', code: 'RU' },
    { name: 'RWANDA', code: 'RW' },
    { name: 'Saint Helena', code: 'SH' },
    { name: 'Saint Kitts and Nevis', code: 'KN' },
    { name: 'Saint Lucia', code: 'LC' },
    { name: 'Saint Pierre and Miquelon', code: 'PM' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC' },
    { name: 'Samoa', code: 'WS' },
    { name: 'San Marino', code: 'SM' },
    { name: 'Sao Tome and Principe', code: 'ST' },
    { name: 'Saudi Arabia', code: 'SA' },
    { name: 'Senegal', code: 'SN' },
    { name: 'Serbia', code: 'RS' },
    { name: 'Seychelles', code: 'SC' },
    { name: 'Sierra Leone', code: 'SL' },
    { name: 'Singapore', code: 'SG' },
    { name: 'Slovakia', code: 'SK' },
    { name: 'Slovenia', code: 'SI' },
    { name: 'Solomon Islands', code: 'SB' },
    { name: 'Somalia', code: 'SO' },
    { name: 'South Africa', code: 'ZA' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { name: 'Spain', code: 'ES' },
    { name: 'Sri Lanka', code: 'LK' },
    { name: 'Sudan', code: 'SD' },
    { name: 'Suriname', code: 'SR' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    { name: 'Swaziland', code: 'SZ' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'Syrian Arab Republic', code: 'SY' },
    { name: 'Taiwan, Province of China', code: 'TW' },
    { name: 'Tajikistan', code: 'TJ' },
    { name: 'Tanzania, United Republic of', code: 'TZ' },
    { name: 'Thailand', code: 'TH' },
    { name: 'Timor-Leste', code: 'TL' },
    { name: 'Togo', code: 'TG' },
    { name: 'Tokelau', code: 'TK' },
    { name: 'Tonga', code: 'TO' },
    { name: 'Trinidad and Tobago', code: 'TT' },
    { name: 'Tunisia', code: 'TN' },
    { name: 'Turkey', code: 'TR' },
    { name: 'Turkmenistan', code: 'TM' },
    { name: 'Turks and Caicos Islands', code: 'TC' },
    { name: 'Tuvalu', code: 'TV' },
    { name: 'Uganda', code: 'UG' },
    { name: 'Ukraine', code: 'UA' },
    { name: 'United Arab Emirates', code: 'AE' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'United States', code: 'US' },
    { name: 'United States Minor Outlying Islands', code: 'UM' },
    { name: 'Uruguay', code: 'UY' },
    { name: 'Uzbekistan', code: 'UZ' },
    { name: 'Vanuatu', code: 'VU' },
    { name: 'Venezuela', code: 'VE' },
    { name: 'Viet Nam', code: 'VN' },
    { name: 'Virgin Islands, British', code: 'VG' },
    { name: 'Virgin Islands, U.S.', code: 'VI' },
    { name: 'Wallis and Futuna', code: 'WF' },
    { name: 'Western Sahara', code: 'EH' },
    { name: 'Yemen', code: 'YE' },
    { name: 'Zambia', code: 'ZM' },
    { name: 'Zimbabwe', code: 'ZW' },
  ]

  const sexOption = [
    { text: 'Male', value: 'male' },
    { text: 'Female', value: 'female' },
    { text: 'Non-binary', value: 'Non-binary' },
    { text: 'Other', value: 'other' },
  ]

  const educationList = [
    { text: 'No formal education', value: 'No formal education' },
    { text: 'Primary education', value: 'Primary education' },
    {
      text: 'Secondary education or high school',
      value: 'Secondary education or high school',
    },
    { text: 'High School Diploma/GED', value: 'GED' },
    { text: "Bachelor's Degree", value: "Bachelor's Degree" },
    { text: "Master's Degree", value: "Master's Degree" },
    { text: 'Doctorate or higher', value: 'Doctorate or higher' },
    { text: 'Other', value: 'Other' },
  ]
  const deviceList = [
    { text: 'Web', value: 'web' },
    { text: 'Android', value: 'android' },
    { text: 'iOS', value: 'ios' },
  ]

  useEffect(() => {
    if (isSuccess && !isLoading) {
      setUserData(usersList?.data || [])
      setPageCount(usersList?.pageCount)
      setTotalRows(usersList?.totalRows)
      setTotalVerificationCount(usersList?.totalVerificationCount)
      setTotalPendingVerification(usersList?.totalPendingVerification)
      setTotalGuestCount(usersList?.totalGuestCount)
      setFixTotalFilteredUsers(usersList?.fixTotalFilteredUsers)
      setFixVerifiedCount(usersList?.fixVerifiedCount)
      setFixGuestCount(usersList?.fixGuestCount)
      setPageIndex(usersList?.pageIndex || 1)
      setTotalUsersWithoutSearchAndFilters(usersList?.totalUsersWithoutSearchAndFilters)
    }
  }, [isSuccess, usersList, isLoading])

  return (
    <div>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6 ">
        {/* Cards Container */}
        {/* <CContainer>
          <CRow className="gap-6 flex justify-center">
            {[
              { title: fixTotalFilteredUsers, text: 'Total Active Users' },
              { title: fixVerifiedCount, text: 'Verified Users' },
              {
                title: fixGuestCount,
                text: 'Guest Users',
              },
            ].map((item, index) => (
              <CCol md="4" key={index}>
                <CCard
                  style={{
                    backgroundColor: '#175676', // Dark blue background
                    borderRadius: '1rem',
                    padding: '20px',
                    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.15)',
                    transition: 'transform 300ms ease-in-out',
                    transform: 'scale(1)',
                    marginBottom: '20px',
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                  onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                >
                  <CCardBody style={{ padding: '20px', textAlign: 'left' }}>
                    <CCardTitle
                      style={{
                        color: '#ffffff',
                        fontSize: '1.5rem', // Increased font size
                        fontWeight: 'bold',
                        marginBottom: '8px',
                      }}
                    >
                      {item.title}
                    </CCardTitle>
                    <CCardText
                      style={{
                        color: '#e0e0e0',
                        fontSize: '18px', // Increased font size
                        fontWeight: '600',
                      }}
                    >
                      {item.text}
                    </CCardText>
                  </CCardBody>
                </CCard>
              </CCol>
            ))}
          </CRow>
        </CContainer> */}
      </div>

      {/* Date Filter Section */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: '10px',
          backgroundColor: '#fff',
          padding: '10px',
          width: '100%',
        }}
      >
        <select
          value={dateFilterType}
          onChange={handleDateFilterTypeChange}
          style={{
            flex: '1 1 100px',
            padding: '8px 10px',
            border: '1px solid #000',
            borderRadius: '5px',
            fontSize: '16px',
            cursor: 'pointer',
            minWidth: '100px',
            maxWidth: '200px',
          }}
        >
          <option value="custom">Custom</option>
          <option value="day">Day</option>
          <option value="week">Week</option>
          <option value="month">Month</option>
        </select>

        {dateFilterType === 'day' ? (
          <div
            style={{
              flex: '1 1 200px',
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              position: 'relative',
              minWidth: '200px',
              cursor: 'pointer',
              border: '1px solid #000',
              borderRadius: '5px',
              padding: '6px 8px',
              backgroundColor: 'white',
            }}
          >
            <label style={{ fontSize: '16px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
              Select Date:
            </label>
            <DatePicker
              selected={new Date(fromDate)}
              onChange={handleFromDateChange}
              dateFormat="MM-dd-yyyy"
              customInput={
                <input
                  style={{
                    flex: 1,
                    border: 'none',
                    fontSize: '16px',
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                    outline: 'none',
                    width: '100%',
                  }}
                />
              }
            />
          </div>
        ) : (
          <>
            <div
              style={{
                flex: '1 1 200px',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                position: 'relative',
                minWidth: '200px',
                cursor: 'pointer',
                border: '1px solid #000',
                borderRadius: '5px',
                padding: '6px 8px',
                backgroundColor: 'white',
              }}
            >
              <label style={{ fontSize: '16px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                From Date:
              </label>
              <DatePicker
                selected={new Date(fromDate)}
                onChange={handleFromDateChange}
                filterDate={disableFromDatesAfterToDate}
                dateFormat="MM-dd-yyyy"
                customInput={
                  <input
                    style={{
                      flex: 1,
                      border: 'none',
                      fontSize: '16px',
                      backgroundColor: 'transparent',
                      cursor: 'pointer',
                      outline: 'none',
                      width: '100%',
                    }}
                  />
                }
              />
            </div>

            <div
              style={{
                flex: '1 1 200px',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                position: 'relative',
                minWidth: '200px',
                cursor: dateFilterType === 'custom' ? 'pointer' : 'not-allowed',
                border: '1px solid #000',
                borderRadius: '5px',
                padding: '6px 8px',
                backgroundColor: dateFilterType === 'custom' ? 'white' : '#f0f0f0',
              }}
            >
              <label style={{ fontSize: '16px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                To Date:
              </label>
              <DatePicker
                selected={new Date(toDate)}
                onChange={handleToDateChange}
                dateFormat="MM-dd-yyyy"
                disabled={dateFilterType !== 'custom'}
                filterDate={disableToDatesBeforeFromDate}
                customInput={
                  <input
                    style={{
                      flex: 1,
                      border: 'none',
                      fontSize: '16px',
                      backgroundColor: 'transparent',
                      cursor: dateFilterType === 'custom' ? 'pointer' : 'not-allowed',
                      outline: 'none',
                      color: dateFilterType === 'custom' ? 'black' : '#666',
                      width: '100%',
                    }}
                  />
                }
              />
            </div>
          </>
        )}
      </div>

      {/* Filters Section */}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
          padding: '10px',
          boxSizing: 'border-box',
          gap: '10px',
        }}
      >
        {/* Search Bar */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid black',
            borderRadius: '6px',
            padding: '8px 12px',
            flex: '1 1 260px',
            maxWidth: '400px',
            height: '40px',
            minWidth: '200px',
          }}
        >
          <span style={{ fontSize: '18px', color: '#004b74', marginRight: '8px' }}>🔍</span>
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              border: 'none',
              outline: 'none',
              fontSize: '16px',
              width: '100%',
              color: '#555',
              height: '100%',
            }}
          />
        </div>

        {/* Different Select Dropdowns */}
        <select
          value={selectedGender}
          onChange={(e) => setSelectedGender(e.target.value)}
          style={{
            border: '1px solid black',
            borderRadius: '6px',
            padding: '8px 12px',
            fontSize: '14px',
            cursor: 'pointer',
            flex: '1 1 120px',
            height: '40px',
            minWidth: '120px',
          }}
        >
          <option value="" style={{ textAlign: 'center' }}>
            Gender
          </option>
          {sexOption.map((option) => (
            <option key={option.value} value={option.value} style={{ textAlign: 'center' }}>
              {option.text}
            </option>
          ))}
        </select>

        <select
          value={selectedEducation}
          onChange={(e) => setSelectedEducation(e.target.value)}
          style={{
            border: '1px solid black',
            borderRadius: '6px',
            padding: '8px 12px',
            fontSize: '14px',
            cursor: 'pointer',
            flex: '1 1 120px',
            height: '40px',
            minWidth: '120px',
          }}
        >
          <option value="" style={{ textAlign: 'center' }}>
            Education
          </option>
          {educationList.map((option) => (
            <option key={option.value} value={option.value} style={{ textAlign: 'center' }}>
              {option.text}
            </option>
          ))}
        </select>

        <select
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
          style={{
            border: '1px solid black',
            borderRadius: '6px',
            padding: '8px 12px',
            fontSize: '14px',
            cursor: 'pointer',
            flex: '1 1 120px',
            height: '40px',
            minWidth: '120px',
          }}
        >
          <option value="" style={{ textAlign: 'center' }}>
            Country
          </option>
          {allContries.map((country) => (
            <option key={country.code} value={country.code} style={{ textAlign: 'center' }}>
              {country.name}
            </option>
          ))}
        </select>

        <select
          value={selectedPlatform}
          onChange={(e) => setSelectedPlatform(e.target.value)}
          style={{
            border: '1px solid black',
            borderRadius: '6px',
            padding: '8px 12px',
            fontSize: '14px',
            cursor: 'pointer',
            flex: '1 1 120px',
            height: '40px',
            minWidth: '120px',
          }}
        >
          <option value="" style={{ textAlign: 'center' }}>
            Platform
          </option>
          {deviceList.map((option) => (
            <option key={option.value} value={option.value} style={{ textAlign: 'center' }}>
              {option.text}
            </option>
          ))}
        </select>
      </div>

      {/* Reset Filters Button */}
      {isAnyFilterActive() && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            padding: '0 10px 10px',
          }}
        >
          <button
            onClick={handleResetFilters}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              backgroundColor: '#175676',
              padding: '10px 18px',
              borderRadius: '8px',
              border: '1px solid black',
              color: '#fff',
              fontWeight: '600',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
              cursor: 'pointer',
            }}
            onMouseOver={(e) => {
              e.target.style.backgroundColor = '#134d62'
              e.target.style.boxShadow = '0 6px 10px rgba(0, 0, 0, 0.15)'
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = '#175676'
              e.target.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)'
            }}
          >
            <RotateCcw size={16} />
            Reset Filters
          </button>
        </div>
      )}

      {/* Data Table */}
      <ServerDataTable
        columns={UsersCols}
        data={userData}
        noDataText="No Users found"
        onPaginationChange={handlePaginationChange}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalRows={totalRows}
        isLoading={isLoading}
        onSortingChange={handleSortingChange}
        genderFilter={selectedGender}
      />
    </div>
  )
}

export default GetInactiveUsers
