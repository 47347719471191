// @ts-ignore
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getSortedRowModel,
  ColumnFiltersState,
  SortingState,
  PaginationState,
} from '@tanstack/react-table'
import React, { useState } from 'react'
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md'
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa'
import { LuArrowUpDown } from 'react-icons/lu'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
// import 'bootstrap/dist/css/bootstrap.min.css'
import { useEffect } from 'react'

// Chevron Icons
const ChevronRightCircle: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="12" fill="#3498db" />
      <path
        fill="white"
        fillRule="evenodd"
        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
        transform="scale(0.8) translate(7,7)"
      />
    </svg>
  )
}

const ChevronLeftCircle: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="12" fill="#3498db" />
      <path
        fill="white"
        fillRule="evenodd"
        d="M10.354 1.646a.5.5 0 0 1 0 .708L4.707 8l5.647 5.646a.5.5 0 1 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
        transform="scale(0.8) translate(7,7)"
      />
    </svg>
  )
}

// Game Icon
const GameIcon = (
  <svg width="18" height="18" viewBox="0 0 25 25" fill="black" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9481 2.10404C11.4548 2.18112 11.7358 1.92211 7.03942 6.63006C6.10201 7.56973 5.29062 8.39373 5.23641 8.46112C5.1821 8.5286 5.12525 8.59279 5.11001 8.6039C5.04714 8.64954 4.90897 9.11288 4.90897 9.27825C4.90897 9.56013 5.00968 9.89256 5.1918 10.2122C5.34248 10.4767 5.70605 10.7121 6.10643 10.8046C6.31057 10.8517 18.1888 10.8586 18.3429 10.8116C18.3987 10.7946 18.5153 10.76 18.602 10.7349C19.4431 10.4903 19.8339 9.31524 19.3256 8.559C19.2272 8.41246 13.357 2.52032 13.1387 2.34884C12.9763 2.22131 12.7373 2.12568 12.5259 2.10366C12.4103 2.09152 12.2921 2.0775 12.2632 2.07232C12.2343 2.06724 12.0925 2.08145 11.9481 2.10404ZM15.1611 6.40361C17.2496 8.4919 18.0067 9.26997 18.0067 9.32794V9.40691L12.2374 9.39806C6.84507 9.38978 6.46756 9.38536 6.45692 9.32983C6.44017 9.24314 12.1358 3.55825 12.2392 3.55825C12.2937 3.55825 13.1303 4.37293 15.1611 6.40361ZM6.27471 13.8578C5.74925 13.9613 5.58389 14.0292 5.40506 14.2151C5.26803 14.3576 5.01429 14.7592 5.01354 14.8349C5.01325 14.8638 4.99029 14.9504 4.96252 15.0275C4.83565 15.3794 4.92958 15.7948 5.22361 16.1832C5.29429 16.2766 11.2006 22.1843 11.3352 22.2963C11.6979 22.5981 12.4573 22.664 12.9141 22.4335C13.1693 22.3048 19.0625 16.479 19.3262 16.0948C19.8576 15.3206 19.4153 14.125 18.5235 13.9246C18.4222 13.9019 18.2823 13.8688 18.2127 13.8512C18.0484 13.8097 6.48667 13.8161 6.27471 13.8578ZM18.0379 15.3182C18.0788 15.3946 12.3591 21.1386 12.2422 21.1386C12.1596 21.1386 6.44987 15.4372 6.44987 15.3547C6.44987 15.2587 6.32582 15.2607 12.2708 15.2579C17.7592 15.2553 18.0056 15.2578 18.0379 15.3182Z"
      fill="black"
    />
  </svg>
)

// Props Interface
interface ServerDataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  pageCount: number
  pageSize: number
  pageIndex: number
  totalRows: number
  onPaginationChange: (pagination: { pageIndex: number; pageSize: number }) => void
  onSortingChange?: (sorting: SortingState) => void
  onFilterChange?: (filters: ColumnFiltersState) => void
  searchColumn?: string
  isLoading?: boolean
  noDataText: string
  genderFilter?: string
}

// Sort Icon Component
interface SortIconProps {
  column: {
    getCanSort: () => boolean
    getIsSorted: () => 'asc' | 'desc' | false
    toggleSorting: (asc: boolean) => void
    clearSorting: () => void
  }
}

const SortIcon: React.FC<SortIconProps> = ({ column }) => {
  if (!column.getCanSort()) return null

  const sorted = column.getIsSorted()

  const handleSort = () => {
    if (!sorted) {
      column.toggleSorting(false) // Ascending
    } else if (sorted === 'asc') {
      column.toggleSorting(true) // Descending
    } else {
      column.clearSorting() // No sorting
    }
  }

  return (
    <span
      className="w-4 h-4 flex justify-center items-center ml-1 cursor-pointer"
      onClick={handleSort}
    >
      {GameIcon}
    </span>
  )
}

// Main Table Component
function ServerDataTable<TData, TValue>({
  columns,
  data,
  pageCount = 1,
  pageSize = 10,
  pageIndex = 1,
  totalRows = 100,
  onPaginationChange,
  onSortingChange,
  onFilterChange,
  searchColumn,
  isLoading = false,
  noDataText,
}: ServerDataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [rowSelection, setRowSelection] = useState({})
  const [{ pageSize: tablePageSize, pageIndex: tablePageIndex }, setPagination] =
    useState<PaginationState>({
      pageSize: pageSize,
      pageIndex: pageIndex,
    })

  const pagination = {
    pageSize: tablePageSize,
    pageIndex: tablePageIndex,
  }

  const table = useReactTable({
    data,
    columns,
    pageCount: pageCount,
    state: {
      sorting,
      columnFilters,
      rowSelection,
      pagination,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
    enableSorting: true,
    autoResetPageIndex: false,
  })

  React.useEffect(() => {
    if (data.length === 0 && pageIndex !== 1) {
      table.setPageIndex(1)
    }
    onPaginationChange(pagination)
  }, [pagination])

  React.useEffect(() => {
    onSortingChange?.(sorting)
  }, [sorting])

  React.useEffect(() => {
    onFilterChange?.(columnFilters)
  }, [columnFilters])

  if (isLoading) {
    return (
      <div className="loaderSection">
        <div className="wrapperloader">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    )
  }

  return (
    <div className="mb-3">
      <div className="d-flex justify-content-between align-items-center mb-5 mt-4">
        <h5 className="mb-0">Users: {totalRows}</h5>
        <div className="d-flex align-items-center gap-3">
          {totalRows > 0 && (
            <div className="text-muted">
              {`${(pageIndex - 1) * pageSize + 1} - ${Math.min(
                pageIndex * pageSize,
                totalRows,
              )} of ${totalRows}`}
            </div>
          )}
          <div className="d-flex gap-1">
            <button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className={`btn btn-link p-0 ${!table.getCanPreviousPage() ? 'disabled' : ''}`}
              onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
              onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
            >
              <ChevronLeftCircle />
            </button>
            <button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              className={`btn btn-link p-0 ${!table.getCanNextPage() ? 'disabled' : ''}`}
              onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
              onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
            >
              <ChevronRightCircle />
            </button>
          </div>
        </div>
      </div>
      <div className="table-responsive rounded-md w-full">
        <table
          className="table table-sm table-hover"
          style={{ tableLayout: 'auto', width: 'full', height: 'full' }}
        >
          <thead className="bg-light">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="text-muted text-sm px-3 py-2"
                    style={{ minWidth: '150px', whiteSpace: 'nowrap' }}
                  >
                    <div
                      className="flex items-center justify-between"
                      style={{ display: 'flex', gap: '5px' }}
                    >
                      {!header.isPlaceholder &&
                        flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getCanSort() && <SortIcon column={header.column} />}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={columns.length} className="text-center py-2">
                  <div className="d-flex justify-content-center gap-2">
                    <div className="spinner-border text-success" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <div className="text-muted">Fetching data</div>
                </td>
              </tr>
            ) : data.length === 0 ? (
              <tr>
                <td colSpan={columns.length} className="text-center py-2">
                  <span className="text-dark">{noDataText ? noDataText : 'No results found.'}</span>
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="hover:bg-gray-50">
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="px-3 py-2 border-bottom"
                      style={{ transition: 'transform 300ms ease-in-out' }}
                      onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.02)')}
                      onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end align-items-center mb-5">
        <div className="d-flex align-items-center gap-3">
          {totalRows > 0 && (
            <div className="text-muted">
              {`${(pageIndex - 1) * pageSize + 1} - ${Math.min(
                pageIndex * pageSize,
                totalRows,
              )} of ${totalRows}`}
            </div>
          )}
          <div className="d-flex gap-1">
            <button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className={`btn btn-link p-0 ${!table.getCanPreviousPage() ? 'disabled' : ''}`}
              onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
              onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
            >
              <ChevronLeftCircle />
            </button>
            <button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              className={`btn btn-link p-0 ${!table.getCanNextPage() ? 'disabled' : ''}`}
              onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
              onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
            >
              <ChevronRightCircle />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServerDataTable
